import { Box, Typography } from '@mui/material'
import React from 'react'

type ResponseReceivedPageProps = {
  customViewHeight?: boolean
}

const ResponseReceivedPage = (props: ResponseReceivedPageProps) => {
  const { customViewHeight } = props
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height={customViewHeight ? 'inherit' : '100vh'}
      marginTop={customViewHeight ? '32px' : ''}
      gap="16px"
    >
      <Typography variant="h4">
        Thanks, your response has been recorded!
      </Typography>
      <Typography variant="p2">You can now close this tab.</Typography>
    </Box>
  )
}

export default ResponseReceivedPage

ResponseReceivedPage.defaultProps = {
  customViewHeight: false,
}
